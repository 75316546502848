<template>
  <b-container class="p-4">
    <b-row>
      <b-col><h4>NetSuite Clients sync</h4></b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-button size="sm" class="mr-3" variant="outline-secondary" @click="fetchNetSuiteClients('initial')">initial</b-button>
        <b-button size="sm" class="mr-3" variant="success" @click="fetchNetSuiteClients('published')">published</b-button>
        <b-button size="sm" class="mr-3" variant="danger" @click="fetchNetSuiteClients('error')">error</b-button>
        <b-button size="sm" class="mr-3" variant="secondary" @click="fetchNetSuiteClients('pending')">pending</b-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="d-flex flex-column justify-content-center align-items-center">
        <small>Showing clients with status {{status_to_show}}</small>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col cols="12">
        <b-table :fields="clients.fields" :items="clients.data" :busy="clients.loading"
                 outlined striped small :show-empty="true" empty-text="No clients">
          <template #cell(internal_id)="row">
            <router-link :to="`/onboarding/client/edit/${row.item.internal_id}`">
              {{row.item.internal_id}}
            </router-link>
          </template>
          <template #cell(details)="row">
            <b-badge @click="showDetails(row.item)" role="button">details</b-badge>
          </template>
        </b-table>
      </b-col>
    </b-row>

    <b-modal id="netsuite-client-details">
      <b-button v-if="syncing_client" variant="secondary" :disabled="true"><b-spinner type="border" small/></b-button>
      <b-button v-if="showSyncButton" variant="primary" @click="syncToNetSuite">Retry sync</b-button>

      <pre>{{selected_client}}</pre>
    </b-modal>
  </b-container>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';

export default {
  name: 'ClientsNetSuiteList',
  data() {
    return {
      status_to_show: 'error',
      syncing_client: false,
      selected_client: {},
      clients: {
        loading: false,
        fields: [
          { key: 'uuid', label: 'uuid' },
          { key: 'type', label: 'type' },
          { key: 'internal_id', label: 'internal_id' },
          { key: 'netsuite_id', label: 'netsuite_id' },
          { key: 'status', label: 'status' },
          { key: 'updated_at', label: 'updated_at' },
          { key: 'details', label: 'details' },
        ],
        data: [],
      },
    };
  },
  computed: {
    showSyncButton() {
      if (this.syncing_client) {
        return false;
      }
      if (this.selected_client.status === 'error') {
        return true;
      }
      return false;
    },
  },
  beforeMount() {
    this.fetchNetSuiteClients('error');
  },
  methods: {
    showDetails(client) {
      this.selected_client = client;
      this.$bvModal.show('netsuite-client-details');
    },
    syncToNetSuite() {
      this.syncing_client = true;
      axios.post(`v1/netsuite/clients/${this.selected_client.internal_id}/sync`)
        .then(() => {
          Vue.prototype.$noty.success('Sync to NetSuite success');
        })
        .catch(error => {
          Vue.prototype.$noty.error('Sync to NetSuite error');
          console.error(error);
        })
        .finally(() => {
          this.syncing_client = false;
        });
    },
    fetchNetSuiteClients(status) {
      this.status_to_show = status;
      this.clients.loading = true;
      return axios.get('v1/netsuite/clients', { params: { status: this.status_to_show } })
        .then(response => {
          this.clients.data = response.data;
        }).catch(e => {
          Vue.prototype.$noty.error('Cannot fetch clients', e);
        }).finally(() => {
          this.clients.loading = false;
        });
    },
  },
};
</script>
